<!--suppress ALL -->
<template>
  <div class="main-berkas">
    <div v-if="isian.active">
      <div
        class="modal fade"
        :class="modalShow ? 'show' : ''"
        @click="close"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        style="display: block"
      >
        <div class="modal-dialog modal-dialog-sm">
          <div class="modal-content" @click.stop>
            <form @submit.prevent="action">
              <div class="modal-header">
                <h5 class="modal-title">Informasi Berkas</h5>
                <button
                  type="button"
                  @click="close"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="form-group">
                  <label>Foto diri</label>
                  <div class="form-inline">
                    <div class="form-check form-check-inline">
                      <input
                        v-model="isian.is_fotodiri"
                        class="form-check-input"
                        type="radio"
                        name="is-fotodiri"
                        id="fotodiri-ya"
                        value="Y"
                      />
                      <label class="form-check-label" for="fotodiri-ya"
                        >Ya</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        v-model="isian.is_fotodiri"
                        class="form-check-input"
                        type="radio"
                        name="is-fotodiri"
                        id="fotodiri-tidak"
                        value="T"
                      />
                      <label class="form-check-label" for="fotodiri-tidak"
                        >Tidak</label
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="jenis-berkas">Jenis Berkas</label>
                  <select
                    v-model="isian.id_kategori_image"
                    type="text"
                    name="Jenis Berkas"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('Jenis Berkas') }"
                    class="form-control"
                    id="jenis-berkas"
                  >
                    <option :value="null">Pilih Kategori</option>
                    <option
                      v-for="(kategori, id) in kategori_berkas"
                      :key="id"
                      :value="kategori.id"
                      v-text="kategori.nama"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('Jenis Berkas')"
                    class="invalid-feedback"
                    v-text="errors.first('Jenis Berkas')"
                  ></div>
                </div>

                <div class="form-group">
                  <label for="keterangan">Keterangan</label>
                  <textarea
                    v-model="isian.description"
                    name="Keterangan"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('Keterangan') }"
                    id="keterangan"
                    rows="4"
                    class="form-control"
                  ></textarea>
                  <div
                    v-show="errors.has('Keterangan')"
                    class="invalid-feedback"
                    v-text="errors.first('Keterangan')"
                  ></div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="submit" class="btn btn-success">Simpan</button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="modalShow ? 'show' : ''"></div>
    </div>

    <div class="card mt-4">
      <div
        class="card-header text-white bg-dark d-flex justify-content-between align-items-center"
      >
        <h6 class="mb-0">
          <fa-icon :icon="['fa', 'file-alt']" class="mr-3" />Berkas
        </h6>
        <input
          @change="setBerkas"
          id="berkas"
          type="file"
          ref="form-berkas"
          class="custom-file-input d-none"
          accept=".jpg,.png"
          multiple
        />
        <label class="btn btn-sm btn-primary m-0" for="berkas"
          ><fa-icon :icon="['fa', 'upload']" class="mr-3" />Upload</label
        >
      </div>
      <div class="bg-light py-2 px-3">
        <small class="text-info"
          >*berkas berupa ekstensi gambar (JPG / PNG)
          <strong>Minimal berukuran 800x800</strong>, yang memuat lembar scan
          KK, KTP, Ijazah, Sertifikat, dll</small
        >
      </div>
      <hr class="m-0" />
      <div class="card-body d-flex align-items-start flex-wrap pb-0">
        <div
          v-for="(field, id) in berkas"
          :key="id"
          class="d-inline-block mx-2 mb-4 img-thumbnail position-relative cp"
        >
          <div class="flow-btn">
            <fa-icon
              @click.stop="updateTrigger(field)"
              :icon="['fas', 'edit']"
            />
            <fa-icon @click.stop="delBerkas(field)" :icon="['fas', 'times']" />
          </div>
          <small
            class="badge badge-warning position-absolute"
            v-if="field.is_fotodiri == 'Y'"
            >Foto diri</small
          >
          <img
            class="rounded"
            v-auth-image="field.thumbnails_url.medium"
            alt="Berkas"
          />
          <hr class="m-2" />
          <div>
            <div class="float-right">
              <small
                class="badge badge-info b-block"
                v-if="field.kategori"
                v-text="field.kategori"
              ></small>
            </div>
            <p class="m-0 text-info">
              <small v-text="field.description"></small>
            </p>
          </div>
        </div>
      </div>

      <div
        class="mx-4 alert alert-danger"
        role="alert"
        v-if="berkas.length == 0"
      >
        Tidak Terdapat Berkas
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["biodatas"],
  data() {
    return {
      berkas: [],
      isian: {},
      kategori_berkas: [],
      modalShow: false,
    };
  },

  methods: {
    getKategori() {
      if (this.kategori_berkas.length > 0) return;
      this.$axios
        .get("/setting/image/kategori")
        .then((res) => {
          this.kategori_berkas = res.data;
        })
        .catch(() => {});
    },
    getBerkas() {
      if (!this.biodatas.uuid) return;
      this.$store.commit("setLoading", true);
      // this.$topprogressbar.start();
      this.$axios
        .get(`/pendaftar/${this.biodatas.uuid}/berkas`)
        .then((res) => {
          this.berkas = [];
          this.berkas = res.data;
          this.$store.commit("setLoading", false);
          // setTimeout(() => this.$topprogressbar.finish(), 500);
        })
        .catch(() => {
          // this.$topprogressbar.fail();
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    setBerkas(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.upload(files);
    },
    upload(data) {
      let formberkas = new FormData();
      for (let i in data) {
        if (typeof data[i] == "object") formberkas.append("berkas", data[i]);
      }
      this.$store.commit("setLoading", true);
      this.$axios
        .post(`/pendaftar/${this.biodatas.uuid}/berkas`, formberkas)
        .then((res) => {
          this.$root.$emit("setNotif", {
            title: res.data.status,
            message: res.data.deskripsi,
            type: "success",
          });
          this.$store.commit("setLoading", false);
          this.getBerkas();
          this.$refs["form-berkas"].value = null;
        })
        .catch(() => {
          this.$refs["form-berkas"].value = null;
        });
    },
    updateTrigger(field) {
      this.$validator.reset();
      this.$validator.resume();

      this.isian = {
        active: true,
        id: field.id,
        id_kategori_image: field.id_kategori_image,
        is_fotodiri: field.is_fotodiri,
        description: field.description,
      };
    },

    action() {
      let doit = () => {
        var data = {
          id_kategori_image: this.isian.id_kategori_image,
          is_fotodiri: this.isian.is_fotodiri,
          description: this.isian.description,
        };

        this.$store.commit("setLoading", true);
        this.$axios
          .put(`/pendaftar/${this.biodatas.uuid}/berkas/${this.isian.id}`, data)
          .then((res) => {
            this.$store.commit("setLoading", false);
            this.$root.$emit("setNotif", {
              title: res.data.status,
              message: res.data.deskripsi,
              type: "success",
            });
            this.getBerkas();
            this.close();
          })
          .catch(() => {
            this.isian.active = false;
          });
      };
      this.$validator.validateAll().then((result) => {
        if (result) doit();
      });
    },

    delBerkas(field) {
      this.$root.$emit("setNotif", {
        title: "Yakin Hapus!",
        type: "warning",
        customConfirmBtnText: "Hapus",
        useConfirmBtn: true,
        customConfirmBtnClass: "btn btn-danger mr-2",
        customCloseBtnClass: "btn btn-primary",
        // onClose: () => {},
        onConfirm: () => {
          this.$axios
            .delete(`/pendaftar/${this.biodatas.uuid}/berkas/${field.id}`)
            .then((res) => {
              this.$root.$emit("setNotif", {
                title: res.data.status,
                message: res.data.deskripsi,
                type: "success",
              });
              this.getBerkas();
            })
            .catch(() => {});
        },
      });
    },
    close() {
      this.$validator.pause();
      this.modalShow = false;
      document.body.classList.remove("modal-open");
      setTimeout(() => {
        this.isian = { active: false };
      }, 150);
    },
  },

  watch: {
    biodatas: {
      handler() {
        this.getBerkas();
      },
      deep: true,
    },
    isian: {
      handler() {
        if (this.isian.active) {
          this.getKategori();
          setTimeout(() => {
            this.modalShow = true;
            document.body.classList.add("modal-open");
          }, 300);
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.getBerkas();
  },
};
</script>

<style></style>
